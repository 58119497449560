.member-card .profile_picture {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}

.member-card .name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.member-card .age {
  font-size: 18px;
  font-weight: bold;
  color: #666;
  margin-bottom: 10px;
}

.member-card .about_me {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}

.member-card {
  max-width: 500px;
  width: 30vw;
  min-width: 300px;
  display: flex;
  height: 100%;
}

.member-card .container {
  border-radius: 10px;
  box-shadow: 1px 2px 4px rgba(128, 125, 124, 0.5),
    0 0 1px rgba(128, 125, 124, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  margin: 2px;
  border-color: var(--arena);
  display: flex;
  flex: 1;
}
