.SectionTitle.container {
  width: 80%;
  background-color: var(--hueso);
}
.SectionTitle .title {
  font-size: 64px;
  color: var(--arena);
  padding: 32px 0px;
  text-align: left;
  margin-left: 20%;
}
