.map-component {
  height: 40vh;
  width: 100%;
  min-width: 300px;
}

.map-title {
  font-size: 40px;
  background-color: var(--hueso);
  text-align: center;
  width: 50vw;
  min-width: 300px;
}

.map {
  display: flex;
  flex: 1;
  justify-content: center;
}
.map .container {
  width: 50vw;
}
.link {
  color: var(--agua);
  text-decoration: none;
}
