body {
  color: var(--arena);
  background-color: var(bodyBackgroundColor);
  margin: 0;
  font-family: "Iowan Old Style", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  min-width: 300px;
  --arena: #8e8684;
  --agua: #93cdce;
  --hueso: #daccbf;
  --negro: #005b7f;
  --primaryColor: var(--agua);
  --secondaryColor: var(--hueso);
  --bodyBackgroundColor: white;
}

@font-face {
  font-family: "Gill Sans";
  src: local("GillSans"), url("./assets/fonts/GillSans.ttc");
}

@font-face {
  font-family: "Iowan Old Style";
  src: local("Iowan"), url("./assets/fonts/Iowan.otf");
}
